<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label>Title*</label>
              <input
                type="text"
                class="form-control"
                v-model="notice.title"
                placeholder="Enter notice title"
                required
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Expired Date*</label>
              <el-date-picker
                  required
                  class="w-100 p-0"
                  v-model="notice.expired_date"
                  type="date"
                  placeholder="Enter expired date"
                  :format="$datePickerFormat"
                  value-format="yyyy-MM-dd"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Description*</label>
              <textarea
                type="text"
                class="form-control"
                v-model="notice.description"
                required
              ></textarea>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Channels*</label> <br>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="notice.channel" id="inlineCheckbox1" value="APP">
                <label class="form-check-label" for="inlineCheckbox1">APP</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="notice.channel" id="inlineCheckbox2" value="SMS">
                <label class="form-check-label" for="inlineCheckbox2">SMS</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="notice.channel" id="inlineCheckbox3" value="EMAIL">
                <label class="form-check-label" for="inlineCheckbox3">EMAIL</label>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Area*</label>
              <multiselect
                  v-model="notice.audience.area"
                  :options="areas"
                  :multiple="true"
                  placeholder="Select area"
                  :close-on-select="false"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Ward Number*</label>
              <multiselect
                  v-model="notice.audience.ward_number"
                  :options="wordNumbers"
                  :multiple="true"
                  placeholder="Select word numbers"
                  :close-on-select="false"
              ></multiselect>
            </div>
          </div>


        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "EditNotice",
  components: {Multiselect},
  data: () => {
    return {
      serverErrors: [],
      showDismissibleAlert: false
    };
  },
  props: {
    notice: Object,
    areas: {
      default: []
    }
  },
  computed: {
    wordNumbers() {
      return Array.from({ length: 30 }, (_, index) => index + 1);
    }
  },
  methods: {
    onSubmitted() {
      this.$store.dispatch("Settings/loading", true);
      this.$store
        .dispatch("Notice/update", this.notice)
        .then(() => {
          this.$store.dispatch("Settings/loading", false);
          this.$toastr.s('success', "Notice successfully updated.", "Notice Updated!");
          setTimeout(() => {
            this.$emit("onNoticeUpdate");
          }, 250);
        })
        .catch((err) => {
          this.$store.dispatch("Settings/loading", false);
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
          }

          let errors = err.response.data.errors;
          for (let field of Object.keys(errors)) {
            this.$toastr.e("error", errors[field][0], "Error!");
          }
        });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
</style>
