import { render, staticRenderFns } from "./EditNotice.vue?vue&type=template&id=f7ce4448&scoped=true&"
import script from "./EditNotice.vue?vue&type=script&lang=js&"
export * from "./EditNotice.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7ce4448",
  null
  
)

export default component.exports